import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators} from "@angular/forms";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AdminApiService } from "../services/admin.api.service";
import { BaseComponent } from "../components/base.component";
import { NotificationComponent } from "../components/notification.component";

import { IAddNewUser, IChangePasswordModel, INotificationData, IOrganization, IRole } from "../../interfaces/api-models";

@Component({
    selector: "change-password",
    styleUrls: ["change-password.component.scss"],
    templateUrl: "./change-password.component.html",
})
export class ChangePasswordComponent extends BaseComponent {

    form: FormGroup = this.formBuilder.group({
        oldPassword: [, { validators: [Validators.required]}],
        newPassword: [, { validators: [Validators.required]}]
    });

    model: IChangePasswordModel = {} as IChangePasswordModel;

    constructor(
        private readonly adminApiService: AdminApiService,
        private formBuilder: FormBuilder,
        private notificationService: MatSnackBar,
        private modal: MatDialogRef<ChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) private data: string) {
        super();

        this.model.userId = data;
    }

    async changePassword() {

        const result = await this.adminApiService.changePassword(this.model);

        if (result.succeeded) {
            // TODO: create service, localization
            this.notificationService.openFromComponent(NotificationComponent, { data: { messages: ["Password changed"] } as INotificationData,
                horizontalPosition: "right",
                verticalPosition: "top",
                panelClass: "success-notification" });
            this.modal.close(true);
        } else {
            // TODO: create service
            this.notificationService.openFromComponent(NotificationComponent, { data: { messages: result.errors } as INotificationData,
                horizontalPosition: "right",
                verticalPosition: "top",
                panelClass: "error-notification" });
        }
    }

    isFieldValid(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.valid;
        }

        return false;
    }

    getErrorMessage(fieldName: string) {
        const fieldControl = this.form.get(fieldName);
        if (fieldControl) {

            if (fieldControl.hasError("required")) {
                return "You must enter a value"; // TODO: localize
            }

            if (fieldControl.hasError("email")) {
                return "Not a valid email"; // TODO: localize
            }
        }

        return "";
    }
}