<h2 mat-dialog-title>{{isForCopying ? this.resources.Translation.CopyLanguage : (isEdit ? this.resources.Translation.EditTranslation : this.resources.Translation.CreateLanguage)}}</h2>
<mat-dialog-content class="mat-typography">
    <div class="example-header" *ngIf="enumValues != null">
		<form [formGroup]="form" autocomplete="off">
			<mat-form-field>
				<input matInput formControlName="code" [value]="enumValues.code" [(ngModel)]="enumValues.code" placeholder={{resources.Translation.Code}} required>
				<mat-error *ngIf="!isFieldValid('code')">{{getErrorMessage('code')}}</mat-error>
			</mat-form-field>
			<mat-form-field>
				<input matInput formControlName="displayName" [value]="enumValues.displayName" [(ngModel)]="enumValues.displayName" placeholder={{resources.Translation.LanguageName}} required>
				<mat-error *ngIf="!isFieldValid('displayName')">{{getErrorMessage('displayName')}}</mat-error>
			</mat-form-field>
		</form>
	</div>
    <mat-table #table [dataSource]="dataSource" *ngIf="dataSource.data.length > 0" matSort multiTemplateDataRows>
		<!-- columns -->
        <ng-container matColumnDef="expandedRows">
            <th mat-header-cell *matHeaderCellDef>expandedLanguages</th>
            <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length">
                <div class="expanded-content" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="translation-container">
                        <div *ngFor="let row of element.resourceTexts" class="translation-item">
							<div>
								<div><label>{{row.key}}</label></div>
								<div>
									<mat-form-field>
										<input matInput maxlength="100" placeholder={{resources.Translation.Translation}} [value]="row.value" [(ngModel)]="row.value">
										<mat-hint align="end">{{row.value?.length || 0}}/100</mat-hint>
									</mat-form-field>
								</div>
							  </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{resources.Translation.Name}}</th>
            <td mat-cell *matCellDef="let element">
				<button  mat-icon-button *ngIf="expandedElement != element" [class.expanded]="expandedElement == element">
                	<mat-icon>keyboard_arrow_down</mat-icon>
				</button>
				<button  mat-icon-button *ngIf="expandedElement == element" [class.expanded]="expandedElement == null">
					<mat-icon>keyboard_arrow_up</mat-icon>
				</button>
				<strong>{{element.adeName}}</strong>
			</td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: columns" class="element-row" [class.expanded-row]="expandedElement === row"
        (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedRows']" class="detail-row"></tr>
	</mat-table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{resources.Common.Cancel}}</button>
	<button mat-raised-button [disabled]="form.invalid" cdkFocusInitial (click)="isForCopying ? copyLanguage() : addOrUpdateLanguage()">
		{{isForCopying ? resources.Common.Copy : (isEdit ? resources.Common.Update : resources.Common.Add)}}
	</button>
</mat-dialog-actions>