import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialogRef } from "@angular/material/dialog";
import { IAddNewUser, IOrganization, IRole } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { AdminApiService } from "../services/admin.api.service";
import { NotificationService } from "../services/notification.service";
import { OrganizationsApiService } from "../services/organizations.api.service";

@Component({
    selector: "add-new-user",
    styleUrls: ["add-new-user.component.scss"],
    templateUrl: "./add-new-user.component.html",
})
export class AddNewUserComponent extends BaseComponent implements OnInit {

    form: FormGroup = this.formBuilder.group({
        email: [, { validators: [Validators.required, Validators.email]}],
        role: [, { validators: [Validators.required]}],
        organization: [, { validators: [Validators.required, this.selectedOrganizationsValidator()]}]
    });

    newUser: IAddNewUser = {} as IAddNewUser;

    roles: IRole[] = [];
    selectedRole?: IRole;

    organizations: IOrganization[] = [];
    selectedOrganizations: IOrganization[] = [];

    constructor(
        private readonly adminApiService: AdminApiService,
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        private organizationService: OrganizationsApiService,
        private modal: MatDialogRef<AddNewUserComponent>) {
        super();
    }

    async ngOnInit() {
        this.roles = await this.adminApiService.getRoles();
    }
    
    async addNewUser() {

        this.newUser.organizationIds = this.selectedOrganizations.map(x => x.id);

        const result = await this.adminApiService.addUser(this.newUser);

        if (result.succeeded) {
            this.notificationService.showNotification([this.resources.Account.UserCreated], true);
            this.modal.close(true);
        } else {
            this.notificationService.showNotification(result.errors, false);
        }
    }

    isFieldValid(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.valid;
        }

        return false;
    }

    getErrorMessage(fieldName: string) {
        const fieldControl = this.form.get(fieldName);
        if (fieldControl) {

            if (fieldControl.hasError("required")) {
                return this.resources.Error.Required;
            }

            if (fieldControl.hasError("email")) {
                return this.resources.Error.NotValidEmail;
            }
        }

        return "";
    }

    onRoleSelected(event: MatAutocompleteSelectedEvent) {
        this.newUser.roleId = (event.option.value as IRole).id;

        if ((event.option.value as IRole).name == "System Admin") {
            this.selectedOrganizations = [];
            this.form.controls["organization"].setValue(null);
            this.form.controls["organization"].disable();
        } else {
            this.form.controls["organization"].enable();
        }
    }

    formatRole(role: IRole) {
        return role ? role.name : "";
    }

    formatOrgatization(organization: IOrganization) {
        return organization?.name ?? "";
    }

    async onKeyUp(keyboardEvent: any, type: "client" | "dataProvider") {
        const query = keyboardEvent.target.value;

        if (query == null || query.length < 3) {
            this.organizations = [];
        } else {
            this.organizations = await this.organizationService.findByName(query).toPromise();
        }
    }

    onOrganizationSelected(event: MatAutocompleteSelectedEvent) {
        const organizationToAdd = event.option.value as IOrganization;

        if (this.selectedOrganizations.findIndex(x => x.identifier === organizationToAdd.identifier) === -1) {
            this.selectedOrganizations.push(organizationToAdd);
        }

        this.form.controls["organization"].setValue(true);
    }

    removeOrganization(organization: IOrganization) {
        const index = this.selectedOrganizations.findIndex(x => x.identifier === organization.identifier);

        if (index > -1) {
            this.selectedOrganizations.splice(index, 1);
        }

        if (this.selectedOrganizations.length  === 0) {
            this.form.controls["organization"].setValue(null);
        }
    }

    selectedOrganizationsValidator(): ValidatorFn {
        return (): { [key: string]: any } | null => {
            if (this.selectedOrganizations != undefined) {
                return this.selectedOrganizations.length === 0 ? { selectedOrganizations: { value: false } } : null;
            }
            return { selectedOrganizations: { value: true } };
        };
    };
}
