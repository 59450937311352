import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { IOrganizationManagementModel, IOrganizationTypeModel } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { AdminApiService } from "../services/admin.api.service";
import { ModalService } from "../services/modal.service";
import { NotificationService } from "../services/notification.service";
import { ManageOrganizationTypeComponent } from "./manage-organization-type.component";

@Component({
    selector: "add-new-organization",
    styleUrls: ["add-new-organization.component.scss"],
    templateUrl: "./add-new-organization.component.html",
})
export class AddNewOrganizationComponent extends BaseComponent implements OnInit {

    organizationTypes: IOrganizationTypeModel[] = [];
    newOrganization = {} as IOrganizationManagementModel;
    maxAllowedCharacters = 50;

    form: FormGroup = this.formBuilder.group({
        name: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        canSendRequest: [, { validators: [] }],
        canReceiveRequest: [, { validators: [] }],
        authenticationUrl: [, {  validators: [Validators.pattern(new RegExp("([a-z]+\:\/+)([^\/\s]*)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#]*)#?([^ \#]*)"))] }],
        serviceUrl: [, { validators: [Validators.pattern(new RegExp("([a-z]+\:\/+)([^\/\s]*)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#]*)#?([^ \#]*)"))] }],
        region: [, { validators: [Validators.required, Validators.minLength(3), Validators.maxLength(3)]}],
        email: [, { validators: [Validators.required, Validators.email] }],
        orgType: [, { validators: [Validators.required] }],
        streetAddress: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        state: [, { validators: [Validators.maxLength(this.maxAllowedCharacters)] }],
        zipCode: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        city: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        country: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }]
    });

    constructor(
        private readonly adminApiService: AdminApiService,
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        private modal: MatDialogRef<AddNewOrganizationComponent>,
        private readonly modalService: ModalService) {
        super();
    }

    async ngOnInit() {
        this.organizationTypes = await this.adminApiService.getOrganizationTypes();
        this.initOrganizationTypes();
    }

    toggleCheckboxValue(name: "canSendRequest" | "canReceiveRequest") {
        this.newOrganization[name] = !this.newOrganization[name];
    }

    async addNewOrganization() {
        const result = await this.adminApiService.addOrganization(this.newOrganization);

        if (result.succeeded) {
            this.notificationService.showNotification([this.resources.Account.OrganizationCreated], true);
            this.modal.close(true);
        } else {
            this.notificationService.showNotification(result.errors, false);
        }
    }

    isFieldValid(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.valid;
        }

        return false;
    }

    getErrorMessage(fieldName: string) {
        const fieldControl = this.form.get(fieldName);
        if (fieldControl) {

            if (fieldControl.hasError("required")) {
                return this.resources.Error.Required;
            }

            if (fieldControl.hasError("email")) {
                return this.resources.Error.NotValidEmail;
            }

            if (fieldControl.hasError("pattern")) {
                return this.resources.Error.NotValidUrlPattern;
            }

            if (fieldControl.hasError("minlength") || fieldControl.hasError("maxlength")) {
                if (fieldName === "region") {
                    return this.resources.Error.RegionLength;
                }
                if (fieldName === "name") {
                    return this.resources.Error.NameLength;
                }
                if (fieldName === "streetAddress") {
                    return this.resources.Error.AddressLength;
                }
                if (fieldName === "state") {
                    return this.resources.Error.StateLength;
                }
                if (fieldName === "zipCode") {
                    return this.resources.Error.ZipCodeLength;
                }
                if (fieldName === "city") {
                    return this.resources.Error.CityLength;
                }
                if (fieldName === "country") {
                    return this.resources.Error.CountryLength;
                }
            }
        }

        return "";
    }

    orgTypeChanged(orgType: any) {
        this.newOrganization.type = orgType;
    }

    async openManageOrganizationTypeModal() {
        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(ManageOrganizationTypeComponent);
        if (actionCommitted) {
            await this.initOrganizationTypes();
        }   
    }

    private async initOrganizationTypes() {
        this.organizationTypes = await this.adminApiService.getOrganizationTypes();
    }
}
