import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { IOrganizationManagementModel, IOrganizationTypeModel } from "../../interfaces/api-models";
import { AdminApiService } from "../services/admin.api.service";
import { BaseComponent } from "../components/base.component";
import { NotificationService } from "../services/notification.service";
import { ModalService } from "../services/modal.service";
import { MatTableDataSource } from "@angular/material/table";
import { ManageOrganizationTypeComponent } from "./manage-organization-type.component";

@Component({
    selector: "organization-detail",
    styleUrls: ["organization-detail.component.scss"],
    templateUrl: "./organization-detail.component.html",
})

export class OrganizationDetailComponent extends BaseComponent implements OnInit {

    dataSource = new MatTableDataSource<IOrganizationTypeModel>();
    organization!: IOrganizationManagementModel;
    organizationTypes: IOrganizationTypeModel[] = [];

    maxAllowedCharacters = 50;

    form = this.formBuilder.group({
        name: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        authenticationUrl: [, { validators: [Validators.pattern(new RegExp("([a-z]+\:\/+)([^\/\s]*)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#]*)#?([^ \#]*)"))] }],
        serviceUrl: [, { validators: [Validators.pattern(new RegExp("([a-z]+\:\/+)([^\/\s]*)([a-z0-9\-@\^=%&;\/~\+]*)[\?]?([^ \#]*)#?([^ \#]*)"))] }],
        region: [, { validators: [Validators.required, Validators.minLength(3), Validators.maxLength(3)] }],
        email: [, { validators: [Validators.required, Validators.email] }],
        streetAddress: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        state: [, { validators: [Validators.maxLength(this.maxAllowedCharacters)] }],
        zipCode: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        city: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }],
        country: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharacters)] }]
    });

    constructor(
        private readonly adminService: AdminApiService,
        private readonly route: ActivatedRoute,
        private readonly notificationService: NotificationService,
        private readonly formBuilder: FormBuilder,
        private readonly modalService: ModalService) {
        super();
    }

    async ngOnInit() {
        this.organization = await this.adminService.getOrganization(this.route.snapshot.params["organizationId"]);
        this.initOrganizationTypes();
    }

    async updateOrganization() {
        const result = await this.adminService.updateOrganization(this.organization);

        if (result.succeeded) {
            this.notificationService.showNotification([this.resources.Account.OrganizationUpdated], true);
        }
        else {
            this.notificationService.showNotification(result.errors, false);
        }
    }


    isFieldValid(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.valid;
        }

        return false;
    }

    getErrorMessage(fieldName: string) {
        const fieldControl = this.form.get(fieldName);
        if (fieldControl) {

            if (fieldControl.hasError("required")) {
                return this.resources.Error.Required;
            }

            if (fieldControl.hasError("email")) {
                return this.resources.Error.NotValidEmail;
            }

            if (fieldControl.hasError("pattern")) {
                return this.resources.Error.NotValidUrlPattern;
            }

            if (fieldControl.hasError("minlength") || fieldControl.hasError("maxlength")) {
                if (fieldName === "region") {
                    return this.resources.Error.RegionLength;
                }
                if (fieldName === "name") {
                    return this.resources.Error.NameLength;
                }
                if (fieldName === "streetAddress") {
                    return this.resources.Error.AddressLength;
                }
                if (fieldName === "state") {
                    return this.resources.Error.StateLength;
                }
                if (fieldName === "zipCode") {
                    return this.resources.Error.ZipCodeLength;
                }
                if (fieldName === "city") {
                    return this.resources.Error.CityLength;
                }
                if (fieldName === "country") {
                    return this.resources.Error.CountryLength;
                }
            }
        }

        return "";
    }

    toggleCheckboxValue(name: "canSendRequest" | "canReceiveRequest") {
        this.organization[name] = !this.organization[name];
    }

    orgTypeChanged(orgType: any) {
        this.organization.type = orgType;
    }

    async openManageOrganizationTypeModal() {
        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(ManageOrganizationTypeComponent);
        if (actionCommitted) {
            await this.initOrganizationTypes();
        }
    }

    private async initOrganizationTypes() {
        this.organizationTypes = await this.adminService.getOrganizationTypes();
    }
}
