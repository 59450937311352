import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IDetailLog } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { LogsApiService } from "../logs/logs.api.service";

@Component({
    selector: "app-logs",
    templateUrl: "./log-detail.component.html",
    styleUrls: ["./log-detail.component.scss"]
})
export class LogDetailComponent extends BaseComponent implements OnInit {

    log = {} as IDetailLog;
    datePipe = new DatePipe("en-US");

    constructor(
        private readonly router: ActivatedRoute,
        private readonly api: LogsApiService) {
        super();
    }

    async ngOnInit() {
        this.log = await this.api.getLog(this.router.snapshot.params["logId"]);

        this.log.clientRequest = this.getReadableJson(this.log.clientRequest);
        this.log.clientResponse = this.getReadableJson(this.log.clientResponse);

        this.log.dataProviderRequest = this.getReadableJson(this.log.dataProviderRequest);
        this.log.dataProviderResponse = this.getReadableJson(this.log.dataProviderResponse);
        this.log.createdAt = this.getFormattedDate(this.log.createdAt);
    }

    download() {
        this.api.downloadClientResponse(this.log.id);
    }

    private getReadableJson(json: string) {
        if (json == null) {
            return "empty";
        }
        if (json.length === 0) {
            return "";
        }
        return JSON.stringify(JSON.parse(json), null, 4);
    }

    private getFormattedDate(date: string) {
        const result = this.datePipe.transform(date, "dd.MM.yyyy HH:mm");
        return result === null ? "" : result;
    }
}
