import { Component, OnInit } from "@angular/core";
import { IOrganization } from "../../interfaces/api-models";
import { OrganizationsApiService } from "../services/organizations.api.service";

@Component({
    selector: "overall-status",
    templateUrl: "./overall.component.html",
    styleUrls: ["./overall.component.scss"]
})
export class OverallStatusComponent implements OnInit  {
   
    series: { name: string, data: number[] | undefined }[] = [];
    organizations: IOrganization[] = [];

    constructor(private readonly organizationsApi: OrganizationsApiService) {
    }

    async ngOnInit() {
        this.organizations = await this.organizationsApi.getWithOutChartData().toPromise();
    }
}
