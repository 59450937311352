import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDetailLog, ILogs } from "../../interfaces/api-models";
import { ApiService } from "../services/api.service";
import { saveAs } from "file-saver";

@Injectable()
export class LogsApiService extends ApiService {

    getLogs(take: number, skip: number = 0, sort?: string, organizationId?: string, parameters: { [key: string]: any } = {}) {
        let params = new HttpParams();

        if (take != null) {
            params = params.append("take", take.toString());
        }

        if (skip != null) {
            params = params.append("skip", skip.toString());
        }

        if (sort != null) {
            params = params.append("sort", sort.toString());
        }

        for (const key in parameters) {
            if (Object.prototype.hasOwnProperty.call(parameters, key)) {
                params = params.append(key, parameters[key]);
            }
        }

        if (organizationId != null) {
            return this.get<ILogs>(`logs/by-organization/${organizationId}`, { params });
        } else {
            return this.get<ILogs>(`logs`, { params });
        }
        
    }
    
    getLog(logId: string) {
        return this.get<IDetailLog>(`logs/${logId}`).toPromise();
    }

    getRelatedOrganizations(logId: string) {
        return this.get<string[]>(`logs/${logId}/related-organizations`).toPromise();
    }

    downloadClientResponse(logId: string) {
        return this.get(`logs/${logId}/download`, {responseType: "blob" })
            .toPromise()
            .then(response => {
                saveAs(response, logId + "_clientResponse.txt");
            });
    }

    getLocationIdsByQuery(query: string) {
        return this.get<string[]>("logs/search?query=" + query);
    }
}
