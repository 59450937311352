import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { NotificationService } from "app/services/notification.service";
import { TranslationApiService } from "app/services/translation.api.service";
import { ILanguageModel, IEnumResourceModel } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";

@Component({
    selector: "add-new-language",
    styleUrls: ["add-new-language.component.scss"],
    templateUrl: "./add-new-language.component.html",
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({height: "0px", minHeight: "0"})),
            state("expanded", style({height: "*"})),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ],
})
export class AddNewLanguageComponent extends BaseComponent {
    emptyGuid: string = "00000000-0000-0000-0000-000000000000";
    isEdit: boolean = false;
    isForCopying: boolean = false;
    isUpdateForNew: boolean = false;
    enumValues: ILanguageModel | undefined;
    
    columns = ['name'];
    dataSource = new MatTableDataSource<IEnumResourceModel>();
    maxAllowedCharactersForName = 100;
    maxAllowedCharactersForCode = 50;
    expandedElement: any;

    form: FormGroup = this.formBuilder.group({
        displayName: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharactersForName)] }],
        code: [, { validators: [Validators.required, Validators.maxLength(this.maxAllowedCharactersForCode)] }],
    });

    constructor(
        private readonly translationApiService: TranslationApiService,
        private formBuilder: FormBuilder,
        private readonly notificationService: NotificationService,
        private modal: MatDialogRef<AddNewLanguageComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {
        super();
        this.isEdit = data.isEdit ?? false;
        this.isForCopying = data.isForCopying ?? false;
        this.isUpdateForNew = data.isUpdateForNew ?? false;
        this.initEnums(data);
    }

    async addOrUpdateLanguage() {
        if (this.enumValues != null) {
            if (!this.isEdit || this.isUpdateForNew) {
                this.enumValues.id = this.emptyGuid;
                this.enumValues = this.copyTranslationIntoNew(this.enumValues);
            }
            const result = await this.translationApiService.addOrUpdateOrganizationLanguage(this.enumValues);

            if (result.succeeded) {
                let notification = this.isEdit ? this.resources.Translation.LanguageUpdated : this.resources.Translation.LanguageCreated;
                this.notificationService.showNotification([notification], true);
                this.modal.close(true);
            } else {
                this.notificationService.showNotification(result.errors, false);
            }
        }
    }

    async copyLanguage() {
        if (this.enumValues != null) {
            const result = await this.translationApiService.copyOrganizationLanguageToLibrary(this.enumValues);

            if (result.succeeded) {
                let notification = this.resources.Translation.LanguageCopiedToLibrary;
                this.notificationService.showNotification([notification], true);
                this.modal.close(true);
            } else {
                this.notificationService.showNotification(result.errors, false);
            }
        }
    }

    private async initEnums(data: any) {
        if (data.languageId != null) {
            this.enumValues = await this.translationApiService.getLanguageDetail(data.languageId);
        } else {
            this.enumValues = await this.translationApiService.getEnumValues();
            this.enumValues.organizationId = data.organizationId;
        }

        if (this.isForCopying) {
            this.enumValues = this.copyTranslationIntoNew(this.enumValues);
        }

        if (this.isUpdateForNew) {
            this.enumValues.organizationId = data.organizationId;
        }

        this.dataSource.data = this.enumValues.enumResources;
    }

    private copyTranslationIntoNew(data: ILanguageModel): ILanguageModel {
        data.enumResources.forEach(enumResource => {
            enumResource.id = this.emptyGuid,
            enumResource.resourceTexts.forEach(x => x.id = this.emptyGuid);
        });

        return data;
    }

    isFieldValid(fieldName: string) {
        const fieldControl = this.form.get(fieldName);

        if (fieldControl) {
            return fieldControl.valid;
        }

        return false;
    }

    getErrorMessage(fieldName: string) {
        const fieldControl = this.form.get(fieldName);
        if (fieldControl) {

            if (fieldControl.hasError("required")) {
                return this.resources.Error.Required;
            }

            if (fieldControl.hasError("minlength") || fieldControl.hasError("maxlength")) {
                if (fieldName === "displayName") {
                    return this.resources.Error.DisplayNameLength;
                }
                if (fieldName === "code") {
                    return this.resources.Error.CodeLength;
                }
            }
        }

        return "";
    }
}