import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { LocalUserSettingsService } from "app/services/local-user-settings.service";
import { ModalService } from "app/services/modal.service";
import { TranslationApiService } from "app/services/translation.api.service";
import { MessageBoxService } from "common.module/components/message-box";
import { ILanguageModel, ILanguagesModel } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { NotificationService } from "../services/notification.service";
import { AddNewLanguageComponent } from "./add-new-language.component";
import { MatSort } from "@angular/material/sort";

@Component({
    selector: "add-language-library",
    styleUrls: ["add-language-library.component.scss"],
    templateUrl: "./add-language-library.component.html",
})
export class AddLanguageLibraryComponent extends BaseComponent implements OnInit {

    emptyGuid: string = "00000000-0000-0000-0000-000000000000";
    dataSource = new MatTableDataSource<ILanguageModel>();
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    expandedElement: any = null;
    organizationId: string = "";
    columns = ["displayName", "edit", "delete"];
    selectedLibrary: ILanguageModel[] = [];
    libraryLanguages: ILanguageModel[] = [];
    orgLanguages!: ILanguageModel[];
    isLoading = false;
    
    constructor(
        private readonly translationApiService: TranslationApiService,
        private readonly userSettingsService: LocalUserSettingsService,
        private notificationService: NotificationService,
        private messageBoxService: MessageBoxService,
        private modalService: ModalService,
        @Inject(MAT_DIALOG_DATA) data: any) {
        super();
        this.organizationId = data.id;
    }

    async ngOnInit() {
        const userSettings = this.userSettingsService.getSettings();
        this.paginator.pageSize = userSettings.translationManagemntPageSize;
        this.dataSource.paginator = this.paginator;
        
        await this.initiDDENLibraryLanguages();
        this.orgLanguages = [];
        this.dataSource = new MatTableDataSource<ILanguageModel>(this.orgLanguages);
    }

    async ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.initMROLibraryLanguages();
        });

        setTimeout(() => this.initMROLibraryLanguages());
    }

    pageChanged(event: PageEvent) {
        this.userSettingsService.changeState("translationManagemntPageSize", event.pageSize);

        this.initMROLibraryLanguages(event.pageIndex * event.pageSize);
    }

    get currentSize() {
        return this.paginator.pageIndex * this.paginator.pageSize;
    }

    async deleteSelectedLanguage(language: ILanguageModel) {
        const confirm = await this.messageBoxService.confirm(this.resources.Common.DeleteConfirmation, this.resources.Translation.RemoveLanguage + ": " + language.displayName);
        if (confirm) {
            const result = await this.translationApiService.deleteOrganizationLanguage(language.id);
            if (result.succeeded) {
                this.notificationService.showNotification([this.resources.Translation.LanguageDeleted], true);
                await this.initMROLibraryLanguages(this.currentSize);
            } else {
                this.notificationService.showNotification(result.errors, false);
            }
        }
    }

    async editSelectedLanguage(language: ILanguageModel) {
        const obj = {
            organizationId: this.organizationId,
            languageId: language.id,
            languageName: language.displayName,
            code: language.code,
            isEdit: true,
        };

        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(AddNewLanguageComponent, obj);
        if (actionCommitted) {
            await this.initMROLibraryLanguages(this.currentSize);
        }
    }

    async onLanguageSelection(event: any) {
        const obj = {
            organizationId: this.organizationId,
            languageId: event.value.id,
            languageName: event.value.displayName,
            code: event.value.code,
            isUpdateForNew: true,
        };

        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(AddNewLanguageComponent, obj);
        if (actionCommitted) {
            await this.initMROLibraryLanguages(this.currentSize);
        }
        this.clearForm();
    }

    private async initiDDENLibraryLanguages() {
        const data = await this.translationApiService.getLibraryLanguages();
        this.libraryLanguages = data.languages;
    }

    private clearForm() {
        this.selectedLibrary = [];
    }

    private async initMROLibraryLanguages(currentSize: number = 0) {
        const sort = this.getSort();
        const data = await this.translationApiService.getLanguagesOfOrganization(this.organizationId, this.paginator.pageSize, currentSize, sort);

        this.orgLanguages.length = currentSize;
        this.orgLanguages.push(...data.languages);
        this.orgLanguages.length = data.totalCount;

        this.dataSource = new MatTableDataSource<ILanguageModel>(this.orgLanguages);
        this.dataSource.paginator = this.paginator;
    }

    private getSort() {
        if (this.sort == null || this.sort.direction == null || this.sort.direction === "") {
            return undefined;
        }

        return `${this.sort.active}|${this.sort.direction}`;
    }
}