import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { first } from "rxjs/operators";

import { ConfirmDialogComponent } from "app/components/confirm-dialog.component";
import { IConfirmDialogData } from "../../interfaces/api-models";

@Injectable({
    providedIn: "root"
})
export class ModalService {

    constructor(private dialog: MatDialog) {
    }

    async openModalFromTemplate<T>(component: any, data?: any) {
        const modal = this.dialog.open(component, {
            data,
            hasBackdrop: true,
            disableClose: true
        });

        const result = await modal.afterClosed().toPromise() as T;
        return result;
    }

    async openConfirmDialog(message: string, title?: string) {
        const modal = this.dialog.open(ConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: true,
            data : {message, title}
        });

        const result = await modal.afterClosed().toPromise() as boolean;
        return result;
    }
}
