import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";

import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationComponent } from "../components/notification.component";

@Injectable({
    providedIn: "root"
})

export class NotificationService {

    constructor(private readonly notificationService: MatSnackBar) {
    }

    showNotification(messages: string[], success: boolean) {

        this.notificationService.openFromComponent(NotificationComponent, {
            data: { messages: messages },
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: success ? "success-notification" : "error-notification"
        });
    }
}
