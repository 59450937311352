import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot,  CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { LogsApiService } from "app/logs/logs.api.service";
import { UserAccessService } from "app/services/user-access.service";

@Injectable({
    providedIn: "root"
})
export class LogGuard implements CanActivate {
    constructor(private router: Router, private logService: LogsApiService, private userAccessService: UserAccessService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

        if (this.userAccessService.isSystemAdmin()) {
            return true;
        }

        const logId = route.params["logId"];

        return this.logService.getRelatedOrganizations(logId).then(x => {
            const isHaveAccess = this.userAccessService.haveAccessToAnyOrganizations(x);

            if (isHaveAccess) {
                return true;
            }

            this.router.navigate(["/forbidden"], { skipLocationChange: true });
            return false;
        });
    }
}
