import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { BaseComponent } from "src/app/components/base.component";

import { OrganizationsApiService } from "src/app/services/organizations.api.service";
import { ParameterApiService } from "src/app/services/parameter.api.service";
import { StatisticsApiService } from "src/app/services/statistics.api.service";
import { ILogStatistic, ISimplifiedOrganization } from "src/interfaces/api-models";
import { AggregationType, IDropdownOption, RangeType, Status, Perspective } from "src/interfaces/interfaces";

import { DateUtils } from "src/app/statistic/date-utils";
import { ReportService } from "src/app/statistic/report.service";

import * as Highcharts from "highcharts";
import * as _ from "lodash";

// @ts-ignore
import GroupedCategories from "highcharts-grouped-categories/grouped-categories";
import { FormControl } from "@angular/forms";

@Component({
    selector: "app-organizations-requests-report",
    templateUrl: "./organizations-requests-report.component.html",
    styleUrls: ["./organizations-requests-report.component.scss"]
})
export class OrganizationsRequestsReportComponent extends BaseComponent implements OnInit {

    selectMessageTypes = new FormControl();
    
    selectedDateRange = RangeType.Day;
    selectedAggregation = AggregationType.Hour;
    selectedStatus = Status.All;
    selectedOrganizations: string[] = [];
    selectedMessageTypes: number[] = [];
    selectedPerspective = Perspective.Client;
    dataRecieved = false;
    updateFlag = false;

    allowedAggregationTypes: any = {};

    dateRanges: IDropdownOption[] = [];
    aggregations: IDropdownOption[] = [];
    statuses: IDropdownOption[] = [];
    messageTypes: IDropdownOption[] = [];
    perspectives: IDropdownOption[] = [];

    organizations: ISimplifiedOrganization[] = [];

    requestChart: typeof Highcharts = Highcharts;
    requestPieChart: typeof Highcharts = Highcharts;

    requestChartOptions: Highcharts.Options = {
        title: {
            text: this.resources.Organization.RequestPerHour

        },
        yAxis: {
            title: {
                text: this.resources.Organization.Requests
            }
        },
        xAxis: {
            crosshair: true,
            categories: [],
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [
       ]
    };

    requestPieChartOptions: Highcharts.Options = {
        chart: {
            type: "pie"
        },
        title: {
            text: this.resources.Organization.RequestPerHour
        }
    };

    private updateChart$ = new Subject();

    private rawStatisticData: ILogStatistic[] = [];

    constructor(
        public readonly reportService: ReportService,
        private readonly statisticsApiService: StatisticsApiService,
        private readonly organizationsApiService: OrganizationsApiService,
        private readonly dateUtils: DateUtils) {
        super();
    }

    async ngOnInit() {
        this.organizations = await this.organizationsApiService.getSimplifiedOrganizations().toPromise();

        this.messageTypes = await this.reportService.getLogTypesAsync();
        this.statuses = this.reportService.getLogStatuses();
        this.aggregations = this.reportService.getAggregationTypes();
        this.dateRanges = this.reportService.getDateRanges();
        this.perspectives = this.reportService.getPerspectives();

        this.allowedAggregationTypes = this.reportService.getAllowedAggregationTypes();
        this.selectedMessageTypes = this.messageTypes.map(x => x.key);

        this.updateChart$.pipe(debounceTime(1000)).subscribe(() => {
            this.initStatistics();
        });
    }

    getSelectedCount(array: string[] | number[]) {
        return this.reportService.getSelectedCount(array);
    }

    async initStatistics() {

        const fromDate = this.dateUtils.getFromDate(this.selectedDateRange, this.selectedAggregation);
        this.rawStatisticData = await this.statisticsApiService.getStatistics(fromDate, new Date(), this.selectedOrganizations);
        this.dataRecieved = true;

        this.prepareCharts(this.rawStatisticData, this.selectedAggregation);
    }

    reinitCharts() {
        this.updateChart$.next();
    }

    refreshChart() {
        this.prepareCharts(this.rawStatisticData, this.selectedAggregation);
    }

    updateRangeType() {
        const isAggregationTypeDisabled = this.isAggregationTypeDisabled(this.selectedAggregation);

        if (isAggregationTypeDisabled) {
            this.selectedAggregation = this.allowedAggregationTypes[this.selectedDateRange][1];
        }

        this.reinitCharts();
    }

    prepareCharts(data: ILogStatistic[], aggregation: AggregationType) {
        this.prepareRequestChart(data, aggregation);
        this.prepareRequestPieChart(data, aggregation);

        this.updateFlag = true;
    }

    prepareRequestChart(data: ILogStatistic[], aggregation: AggregationType) {
        const series = this.getRequestChartAggregatedData(data, aggregation);

        this.requestChartOptions.title = {
            text: this.getChartTitle("%status% requests for %range% (%aggregation%)", this.selectedDateRange, this.selectedAggregation, this.selectedStatus)
        };

        const categories = this.getXAxisGropedCategories(aggregation);
        (this.requestChartOptions.xAxis as any).categories = categories;

        this.requestChartOptions.series = series;
    }

    prepareRequestPieChart(data: ILogStatistic[], aggregation: AggregationType) {
        const series = this.getRequestChartPieData(data, aggregation);
        this.requestPieChartOptions.series = series;

        this.requestPieChartOptions.title = {
            text: this.getChartTitle("%status% requests for %range%", this.selectedDateRange, this.selectedAggregation, this.selectedStatus)
        };

    }

    isAggregationTypeDisabled(aggregationType: AggregationType) {
        const allowedAggregationTypes = this.allowedAggregationTypes[this.selectedDateRange];
        return !allowedAggregationTypes.some((x: any) => x === aggregationType);
    }

    private getXAxisCategories(aggregationType: AggregationType) {
        const now = new Date();
        const from = this.dateUtils.getFromDate(this.selectedDateRange, this.selectedAggregation);

        const dates = this.dateUtils.getDatesBetween(from, now, aggregationType);

        return dates.map(x => this.dateUtils.formatDate(x, aggregationType));
    }

    private getXAxisGropedCategories(aggregationType: AggregationType) {
        const categories: string[] = this.getXAxisCategories(aggregationType);

        const groupedCategories = _.chain(categories).groupBy(x => {
            const data = x.split(" ");
            return data[0];
        }).mapValues(x => {
            return x.map(s => {
                const data = s.split(" ");
                return data[1];
            });
        }).value();

        const groupedCategoriesObj = Object.keys(groupedCategories).map(key => ({ name: key, categories: groupedCategories[key] }));
        return groupedCategoriesObj;
    }

    private getChartTitle(templateString: string, rangeType: RangeType, aggregationType: AggregationType, status: Status) {
        const rangeText = this.dateRanges.find(x => x.key === rangeType)?.value.toLowerCase();
        const aggregationText = this.aggregations.find(x => x.key === aggregationType)?.value;
        const statusText = this.statuses.find(x => x.key === status)?.value;

        templateString = templateString
            .replace("%range%", rangeText ?? "")
            .replace("%aggregation%", aggregationText ?? "")
            .replace("%status%", statusText ?? "");

        return templateString;
    }

    private getRequestChartAggregatedData(rawData: ILogStatistic[], aggregationType: AggregationType) {

        const categories = this.getXAxisCategories(aggregationType);

        const aggregatedData = _.chain(rawData)
            .filter(x => this.reportService.filterLogByStatus(x, this.selectedStatus))
            .filter(x => this.reportService.filterLogByMessageType(x, this.selectedMessageTypes))
            .filter(x => this.reportService.filterLogByPerspective(x, this.selectedOrganizations, this.selectedPerspective))
            .groupBy(x => this.reportService.groupLog(x, this.selectedPerspective))
            .mapValues(x => {
                return _.chain(x)
                        .groupBy((item: ILogStatistic) => this.dateUtils.formatDate(item.createdAt, aggregationType))
                        .value();
            })
            .mapValues(x => {
                for (const key of categories) { // normalize values
                    if (x[key] == null) {
                        x[key] = [] as any;
                    }
                }
                return x;
            });

        const keys = aggregatedData.keys().sortBy(x => x).value();
        const values = aggregatedData.value();

        const result: Highcharts.SeriesLineOptions[] = [];

        if (keys.length === 0 ) {
            result.push({
                type: "line",
                name: "All",
                data: categories.map(x => 0),
            });

            return result;
        }

        for (const key of keys) {
            const data: Highcharts.SeriesLineOptions = {
                type: "line",
                name: key,
                data: categories.map(x => values[key][x].length)
            };

            result.push(data);
        }

        return result;
    }

    private getRequestChartPieData(rawData: ILogStatistic[], aggregationType: AggregationType) {

        const categories = this.getXAxisCategories(aggregationType);

        const aggregatedData = _.chain(rawData)
            .filter(x => this.reportService.filterLogByStatus(x, this.selectedStatus))
            .filter(x => this.reportService.filterLogByPerspective(x, this.selectedOrganizations, this.selectedPerspective))
            .filter(x => this.reportService.filterLogByMessageType(x, this.selectedMessageTypes))
            .groupBy(x => this.reportService.groupLog(x, this.selectedPerspective));

        const keys = aggregatedData.keys().sortBy(x => x).value();
        const values = aggregatedData.value();

        const result: Highcharts.SeriesOptionsType[] = [];

        if (keys.length === 0 ) {
            result.push({
                type: "pie",
                name: this.resources.Organization.Requests,
                data: [{
                    name: "All",
                    y: 0
                }]
            });

            return result;
        }

        const item: Highcharts.SeriesPieOptions = {
            name: "Requests",
            type: "pie",
            data: []
        };

        for (const key of keys) {
            item.data?.push({
                name: key,
                y : values[key].length
            });
        }

        result.push(item);
        return result;
    }
}