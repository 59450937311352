import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { LocalUserSettingsService } from "app/services/local-user-settings.service";
import { ModalService } from "app/services/modal.service";
import { TranslationApiService } from "app/services/translation.api.service";
import { ILanguageModel, IOrganization, IOrganizationManagementModel } from "src/interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { AddNewLanguageComponent } from "./add-new-language.component";
import { MatSort } from "@angular/material/sort";

@Component({
    selector: "copy-language-to-library",
    styleUrls: ["copy-language-to-library.component.scss"],
    templateUrl: "./copy-language-to-library.component.html",
    animations: [
    trigger("detailExpand", [
      state("collapsed", style({height: "0px", minHeight: "0"})),
      state("expanded", style({height: "*"})),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class CopyLanguageToLibraryomponent extends BaseComponent {

    baseLanguages: string[] = [];
    maxAllowedCharacters = 50;
    dataSource = new MatTableDataSource<IOrganizationManagementModel>();
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    expandedElement: any = null;
    columns = ['name', 'actions'];
    orgLanguages: IOrganizationManagementModel[] = [];
    isLoading = false;

    constructor(
        private readonly translationApiService: TranslationApiService,
        private readonly userSettingsService: LocalUserSettingsService,
        private readonly modalService: ModalService) {
        super();
        this.initLibraryLanguages();
    }

    isAlreadyCopied(row: ILanguageModel): boolean {
       return this.baseLanguages.some(r => r === row.id);
    }

    async ngOnInit() {
        const userSettings = this.userSettingsService.getSettings();
        this.paginator.pageSize = userSettings.translationManagemntPageSize;

        this.orgLanguages = [];
        this.dataSource = new MatTableDataSource<IOrganizationManagementModel>(this.orgLanguages);
    }

    async ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.initOrganizationLanguages();
        });

        setTimeout(() => this.initOrganizationLanguages());
    }

    get currentSize() {
        return this.paginator.pageIndex * this.paginator.pageSize;
    }

    pageChanged(event: PageEvent) {
        this.userSettingsService.changeState("translationManagemntPageSize", event.pageSize);

        this.initOrganizationLanguages(event.pageIndex * event.pageSize);
    }

    async copyLanguageToLibrary(language: ILanguageModel, org: IOrganization) {
        const obj = {
            organizationId: org.id,
            languageId: language.id,
            languageName: language.displayName,
            code: language.code,
            isForCopying: true,
        };

        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(AddNewLanguageComponent, obj);
        if (actionCommitted) {
            await this.initOrganizationLanguages(this.currentSize);
            await this.initLibraryLanguages();
        }
    }

    private async initOrganizationLanguages(currentSize: number = 0) {
        this.isLoading = true;
        const sort = this.getSort();
        const data = await this.translationApiService.getOrganizationsWithLanguages(this.paginator.pageSize, currentSize, sort);

        this.orgLanguages.length = currentSize;
        this.orgLanguages.push(...data.organizations);
        this.orgLanguages.length = data.totalCount;

        this.dataSource = new MatTableDataSource<IOrganizationManagementModel>(this.orgLanguages);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
    }

    private getSort() {
        if (this.sort == null || this.sort.direction == null || this.sort.direction === "") {
            return undefined;
        }

        return `${this.sort.active}|${this.sort.direction}`;
    }

    private async initLibraryLanguages() {
        this.baseLanguages = ((await this.translationApiService.getLibraryLanguages()).languages).map(x => x.baseLanguageId);
    }
}