import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";

import { ILanguageModel } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { LocalUserSettingsService } from "../services/local-user-settings.service";
import { ModalService } from "../services/modal.service";
import { AddNewLanguageComponent } from "./add-new-language.component";
import { MessageBoxService } from "common.module/components/message-box";
import { CopyLanguageToLibraryomponent } from "./copy-language-to-library.component";
import { NotificationService } from "app/services/notification.service";
import { TranslationApiService } from "app/services/translation.api.service";
import { MatSort } from "@angular/material/sort";

@Component({
    selector: "language-library",
    styleUrls: ["language-library.component.scss"],
    templateUrl: "./language-library.component.html",
})
export class LanguageLibraryComponent extends BaseComponent implements OnInit {

    dataSource = new MatTableDataSource<ILanguageModel>();
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    expandedElement: any = null;
    columns = ["displayName", "organizationName", "buttons"];
    languages!: ILanguageModel[];
    isLoading = false;

    constructor(
        private readonly translationApiService: TranslationApiService,
        private readonly userSettingsService: LocalUserSettingsService,
        private readonly modalService: ModalService,
        private notificationService: NotificationService,
        private messageBoxService: MessageBoxService) {
        super();
    }

    async ngOnInit() {
        const userSettings = this.userSettingsService.getSettings();
        this.paginator.pageSize = userSettings.translationManagemntPageSize;

        this.languages = [];
        this.dataSource = new MatTableDataSource<ILanguageModel>(this.languages);
    }

    async ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.initLibraryLanguages();
        });

        setTimeout(() => this.initLibraryLanguages());
    }

    get currentSize() {
        return this.paginator.pageIndex * this.paginator.pageSize;
    }

    pageChanged(event: PageEvent) {
        this.userSettingsService.changeState("translationManagemntPageSize", event.pageSize);

        this.initLibraryLanguages(event.pageIndex * event.pageSize);
    }

    async openEditLanguageLibraryModal(language: ILanguageModel) {
        const obj = {
            organizationId: language.organizationId,
            languageId: language.id,
            languageName: language.displayName,
            code: language.code,
            isEdit: true,
        };

        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(AddNewLanguageComponent, obj);
        
        if (actionCommitted) {
            await this.initLibraryLanguages(this.currentSize);
        }
    }

    async deleteLanguageFromLibraryModal(language: ILanguageModel) {
        const confirm = await this.messageBoxService.confirm(this.resources.Common.DeleteConfirmation, this.resources.Translation.RemoveLanguage + ": " + language.displayName);
        if (confirm) {
            const result = await this.translationApiService.deleteOrganizationLanguage(language.id);
            if (result.succeeded) {
                this.notificationService.showNotification([this.resources.Translation.LanguageDeleted], true);
                await this.initLibraryLanguages(this.currentSize);
            } else {
                this.notificationService.showNotification(result.errors, false);
            }
        }
    }

    async syncToADE(language: ILanguageModel) {
        const result = await this.translationApiService.syncToADE(language.id);

        if (result.succeeded) {
            let notification = this.resources.Translation.LanguageSynced;
            this.notificationService.showNotification([notification], true);
            await this.initLibraryLanguages(this.currentSize);
        } else {
            this.notificationService.showNotification(result.errors, false);
        }
    }

    async openCopyFromMROModal() {
        await this.modalService.openModalFromTemplate<boolean>(CopyLanguageToLibraryomponent);
        await this.initLibraryLanguages(this.currentSize);
    }

    async syncAllLanguages() {
        const confirm = await this.messageBoxService.confirm(this.resources.Account.ProceedConfirmation, this.resources.Translation.SyncAllLanguages);
        if (confirm) {
            const result = await this.translationApiService.syncAllLanguagesToADE(true);
            if (result.succeeded) {
                this.notificationService.showNotification([this.resources.Translation.LanguageSynced], true);
                await this.initLibraryLanguages(this.currentSize);
            } else {
                this.notificationService.showNotification(result.errors, false);
            }
        }
    }

    private async initLibraryLanguages(currentSize: number = 0) {
        this.isLoading = true;
        const sort = this.getSort();
        const data = await this.translationApiService.getLibraryLanguages(this.paginator.pageSize, currentSize, sort);

        this.languages.length = currentSize;
        this.languages.push(...data.languages);
        this.languages.length = data.totalCount;

        this.dataSource = new MatTableDataSource<ILanguageModel>(this.languages);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
    }

    private getSort() {
        if (this.sort == null || this.sort.direction == null || this.sort.direction === "") {
            return undefined;
        }

        return `${this.sort.active}|${this.sort.direction}`;
    }
}