import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";

import { ISimplifiedOrganization, OrganizationType } from "../../interfaces/api-models";
import { BaseComponent } from "../components/base.component";
import { ModalService } from "../services/modal.service";
import { OrganizationsApiService } from "../services/organizations.api.service";
import { LocalUserSettingsService } from "../services/local-user-settings.service";
import { AddNewOrganizationComponent } from "./add-new-organization.component";

@Component({
    selector: "organization-management",
    styleUrls: ["organization-management.component.scss"],
    templateUrl: "./organization-management.component.html"
})
export class OrganizationManagementComponent extends BaseComponent implements OnInit {

    dataSource = new MatTableDataSource<ISimplifiedOrganization>();
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    expandedElement: any = null;

    columns = ["organizationIdentifier", "organizationName", "organizationType"];

    constructor(
        private readonly organizationsApiService: OrganizationsApiService,
        private readonly userSettingsService: LocalUserSettingsService,
        private readonly modalService: ModalService) {
        super();
    }

    async ngOnInit() {
        const userSettings = this.userSettingsService.getSettings();

        this.paginator.pageSize = userSettings.organizationManagementPageSize;
        this.dataSource.paginator = this.paginator;
        await this.initOrganizations();
    }

    async openAddNewOrganizationModal() {
        const actionCommitted = await this.modalService.openModalFromTemplate<boolean>(AddNewOrganizationComponent);
        if (actionCommitted) {
            await this.initOrganizations();
        }
    }

    pageChanged(event: PageEvent) {
        this.userSettingsService.changeState("organizationManagementPageSize", event.pageSize);
    }

    formatOrganizationType(type: OrganizationType) {
        if (type === OrganizationType.CloudProvider) {
            return this.resources.Organization.CloudProvider;
        }
        if (type === OrganizationType.FarmSoftware) {
            return this.resources.Organization.FarmSoftware;
        }

        return this.resources.Organization.MilkRecordingOrganization;
    }

    private async initOrganizations() {
        this.dataSource.data = await this.organizationsApiService.getSimplifiedOrganizations().toPromise();
    }
}
